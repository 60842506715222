import React, { Component } from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import tw from 'tailwind.macro'
import FullHeight from 'react-div-100vh'

import logoGroup from '../images/logo-group-2x.png'

class LogoSlider extends Component {
    renderSlide = () => {
        return (
            <Slide>
                <Logos src={logoGroup} />
            </Slide>
        )
    }

    render() {
        const settings = {
            autoplay: true,
            autoplaySpeed: 0,
            centerMode: true,
            infinite: true,
            lazyload: true,
            vertical: true,
            speed: 4000,
            cssEase: 'linear',
            slidesToShow: 5,
            slidesToScroll: 1,
            initialSlide: 1,
            arrows: false,
            buttons: false,
            swipe: false,
            touchmove: false,
            draggable: false,
            focusOnSelect: false,
            pauseOnHover: false,
            rtl: false,
        }

        return (
            <Wrapper id={'logo-slider'}>
                <Slider {...settings} style={{ height: '100%' }}>
                    {this.renderSlide()}
                    {this.renderSlide()}
                    {this.renderSlide()}
                    {this.renderSlide()}
                    {this.renderSlide()}
                    {this.renderSlide()}
                </Slider>
            </Wrapper>
        )
    }
}

const Wrapper = styled(FullHeight)`
    ${tw`absolute top-0 left-0 bottom-0 overflow-hidden sm:hidden`}
    width: 300px;

    @media (max-width: 1024px) {
        width: 250px;
    }

    @media (max-width: 768px) {
        width: 200px;
    }
`

const Slide = styled.div`
    ${tw`outline-none w-full`}
    margin-bottom: -100px;

    @media (max-width: 1024px) {
        margin-bottom: -80px;
    }

    @media (max-width: 768px) {
        margin-bottom: -70px;
    }
`

const Logos = styled.img`
    ${tw`w-full h-auto`}
`

export default LogoSlider
