const poseProps = {
    logoSlider: {
        hidden: {
            opacity: 0,
            x: -50,
            transition: { duration: 1200 },
        },
        visible: {
            opacity: 1,
            x: 0,
            transition: { duration: 1200 },
        },
    },
    imageSlider: {
        hidden: {
            opacity: 0,
            x: 50,
            transition: { duration: 1200 },
        },
        visible: {
            opacity: 1,
            x: 0,
            transition: { duration: 1200 },
        },
    },
    content: {
        hidden: {
            opacity: 0,
            transition: { duration: 1200 },
        },
        visible: {
            opacity: 1,
            transition: { duration: 1200 },
        },
    },
    mobileSplash: {
        hidden: {
            applyAtEnd: { display: 'none' },
            opacity: 0,
            transition: { duration: 1200 },
        },
        visible: {
            opacity: 1,
            transition: { duration: 1200 },
        },
    },
}

export default poseProps