/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import FullHeight from 'react-div-100vh'
import posed from 'react-pose'
import poseProps from '../pose-props'

import LogoSlider from '../components/logo-slider'
import ImageSlider from '../components/image-slider'

import '../styles/global.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'


class Layout extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isVisible: false,
        }
    }

    componentDidMount() {
        this.setState({
            isVisible: true,
        })
    }

    renderDesktopElements = () => {
        const { isVisible } = this.state

        return (
            <Fragment>
                <PoseLogoSlider pose={isVisible ? 'visible' : 'hidden'}>
                    <LogoSlider />
                </PoseLogoSlider>

                <PoseImageSlider pose={isVisible ? 'visible' : 'hidden'}>
                    <ImageSlider />
                </PoseImageSlider>
            </Fragment>
        )
    }

    render() {
        const { children, isMobile } = this.props

        return (
            <SiteWrapper>
                {/* Desktop Only */}
                {!isMobile && (
                    this.renderDesktopElements()
                )}

                {/* Desktop and Mobile */}
                <Main>{children}</Main>
            </SiteWrapper>
        )
    }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

const SiteWrapper = styled(FullHeight)`
    position: relative;
    overflow: hidden;
`

const PoseLogoSlider = styled(posed.div(poseProps.logoSlider))`
    ${tw`sm:hidden`}
`

const PoseImageSlider = styled(posed.div(poseProps.imageSlider))`
    ${tw`sm:hidden`}
`

const Main = styled(FullHeight)`
    ${tw`mx-auto overflow-y-auto`}

    max-width: calc(100% - 600px);

    @media (max-width: 1024px) {
        max-width: calc(100% - 500px);
    }

    @media (max-width: 768px) {
        max-width: calc(100% - 400px);
    }

    @media (max-width: 767px) {
        max-width: 100%;
    }
`

Layout.propTypes = {
    isMobile: PropTypes.bool,
}

const mapStateToProps = state => ({
    isMobile: state.mobile.isMobile,
})

export default connect(mapStateToProps)(Layout)
