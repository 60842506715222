import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Slider from 'react-slick'
import tw from 'tailwind.macro'
import FullHeight from 'react-div-100vh'

class ImageSlider extends Component {
    renderSlides = () => {
        const { imagesData } = this.props

        if (imagesData.images) {
            return imagesData.images.map((slide, i) => {
                return (
                    <Slide key={i}>
                        <Image src={slide.acf.image.sizes.large} />
                    </Slide>
                )
            })
        }
    }

    render() {
        const { imagesData } = this.props
        
        const settings = {
            autoplay: true,
            autoplaySpeed: 0,
            centerMode: true,
            infinite: true,
            lazyload: true,
            vertical: true,
            speed: 5000,
            cssEase: 'linear',
            slidesToShow: 4,
            slidesToScroll: 1,
            initialSlide: 1,
            arrows: false,
            buttons: false,
            swipe: false,
            touchmove: false,
            draggable: false,
            focusOnSelect: false,
            pauseOnHover: false,
            rtl: true,
        }

        return (
            <Wrapper id={'image-slider'}>
                {imagesData && (
                    <Slider {...settings} style={{ height: '100%' }}>
                        {this.renderSlides()}
                    </Slider>
                )}
            </Wrapper>
        )
    }
}

const Wrapper = styled(FullHeight)`
    ${tw`absolute top-0 right-0 bottom-0 overflow-hidden sm:hidden`}
    width: 300px;

    @media (max-width: 1024px) {
        width: 250px;
    }

    @media (max-width: 768px) {
        width: 200px;
    }
`

const Slide = styled.div`
    ${tw`outline-none w-full`}
    height: 450px;

    @media (max-width: 1024px) {
        height: 375px;
    }

    @media (max-width: 768px) {
        height: 300px;
    }
`

const Image = styled.img`
    ${tw`w-full h-full object-cover`}
`

ImageSlider.propTypes = {
    imagesData: PropTypes.object,
}

const mapStateToProps = state => ({
    imagesData: state.data.imagesData,
})

export default connect(mapStateToProps)(ImageSlider)
