import { combineReducers } from 'redux'
import data from './reducers/data'
import splash from './reducers/splash'
import mobile from './reducers/mobile'

export default combineReducers({
    data,
    splash,
    mobile,
})
