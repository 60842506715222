const dataInitialState = {
    optionsData: undefined,
    pagesData: undefined,
    imagesData: undefined,
}

const data = (state = dataInitialState, action) => {
    switch (action.type) {
        case 'LOAD_DATA':
            return {
                optionsData: action.optionsData,
                pagesData: action.pagesData,
                imagesData: action.imagesData,
            }
        default:
            return state
    }
}

export default data