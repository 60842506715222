const splashInitialState = {
    seenSplash: false
}

const splash = (state = splashInitialState, action) => {
    switch (action.type) {
        case 'UPDATE':
            return {
                seenSplash: action.seenSplash
            }
        default:
            return state
    }
}

export default splash