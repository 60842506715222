const mobileInitialState = {
    isMobile: false
}

const mobile = (state = mobileInitialState, action) => {
    switch (action.type) {
        case 'UPDATE_MOBILE':
            return {
                isMobile: action.isMobile,
            }
        default:
            return state
    }
}

export default mobile